import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Table from "../components/periodic-table/table.module.scss";
import Element from "../components/periodic-table/element";
import Empty from "../components/periodic-table/empty";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Metatags title="Periodic Table of Web Elements | May 22 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-22.png" url="https://cssgrid31.brett.cool" pathname="/may-22" mdxType="Metatags" />
    <div className={Table.canvas}>
      <div className={Table.container}>
  <Element abbr="Ba" title="Balance" background="#0075B5" mdxType="Element" />
  <Element abbr="Sy" title="Symmetry" background="#0075B5" mdxType="Element" />
  <Empty mdxType="Empty" />
  <Empty mdxType="Empty" />
  <Empty mdxType="Empty" />
  <Empty mdxType="Empty" />
  <Element abbr="Ac" title="Accessible" background="#005B10" mdxType="Element" />
  <Element abbr="Sm" title="Semantic" background="#005B10" mdxType="Element" />
  <Element abbr="Sc" title="Scale" background="#0075B5" mdxType="Element" />
  <Element abbr="Ry" title="Rhythm" background="#0075B5" mdxType="Element" />
  <Element abbr="Oa" title="Optimised assets" background="#5A008A" mdxType="Element" />
  <Element abbr="Se" title="Secure" background="#5A008A" mdxType="Element" />
  <Element abbr="Seo" title="Metadata" background="#5A008A" mdxType="Element" />
  <Element abbr="Pf" title="Performant" background="#5A008A" mdxType="Element" />
  <Element abbr="Co" title="Sufficient contrast" background="#005B10" mdxType="Element" />
  <Element abbr="Ntc" title="Non-text content" background="#005B10" mdxType="Element" />
  <Element abbr="Sp" title="Spacing" background="#0075B5" mdxType="Element" />
  <Element abbr="Pr" title="Proximity" background="#0075B5" mdxType="Element" />
  <Element abbr="Svg" title="SVG assets" background="#5A008A" mdxType="Element" />
  <Element abbr="HTML" title="Hypertext Markup Language" background="#5A008A" mdxType="Element" />
  <Element abbr="CSS" title="Cascading Stylesheets" background="#5A008A" mdxType="Element" />
  <Element abbr="Js" title="Javascript" background="#5A008A" mdxType="Element" />
  <Element abbr="Sr" title="Screenreader Accessible" background="#005B10" mdxType="Element" />
  <Element abbr="Cc" title="Closed captioning" background="#005B10" mdxType="Element" />
  <Empty mdxType="Empty" />
  <Element abbr="Cs" title="Consistent" background="#0075B5" mdxType="Element" />
  <Element abbr="Re" title="Responsive" background="#5A008A" mdxType="Element" />
  <Element abbr="Cb" title="Cross-browser support" background="#5A008A" mdxType="Element" />
  <Element abbr="Cr" title="Critical asset prioritisation" background="#5A008A" mdxType="Element" />
  <Element abbr="Fmp" title="First meaningful paint" background="#5A008A" mdxType="Element" />
  <Element abbr="Wc" title="WCAG compliant" background="#005B10" mdxType="Element" />
      </div>
    </div>
    <Footer date={22} prev={true} next={true} mdxType="Footer">
      <p>{`A non-complete list of web elements, built in CSS Grid.`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      